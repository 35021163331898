import React from "react";
import PropTypes from "prop-types";
import FavoriteModalFormButton from "./FavoriteModalFormButton";
import { updateFavoriteCompanies } from "../../utils/router";
import "./FavoriteUpdateCompanies.css";

const FavoriteUpdateCompanies = (props) => {
  const { user, favorite, onSuccess, companies } = props;

  const handleFormSubmit = async (formData, setErrorMessage) => {
    const { token } = user;

    const originalCompanies = favorite.company;
    const resultCompanies = formData.company;

    const additions = resultCompanies.filter((resCompany) => {
      return !originalCompanies.includes(resCompany);
    });
    const subtractions = originalCompanies.filter((origCompany) => {
      return !resultCompanies.includes(origCompany);
    });

    const res = await updateFavoriteCompanies(
      token,
      user.companyName,
      favorite.clientName,
      // favorite.medId,
      additions,
      subtractions,
      setErrorMessage
    );
    if (res) {
      return res;
    }
    return false;
  };

  const favoriteConfig = [
    [
      {
        name: "company",
        text: "Company List",
        type: "checkbox",
        options: companies,
        locked: [user.companyName],
      },
    ],
  ];

  return (
    <FavoriteModalFormButton
      type="company"
      btnTitle="Companies"
      btnClassname="favorite-update-companies-btn"
      modalTitle="Update companies"
      defaultFormData={favorite}
      onFormSubmit={handleFormSubmit}
      onSuccess={onSuccess}
      mode="edit"
      config={favoriteConfig}
    />
  );
};

FavoriteUpdateCompanies.propTypes = {
  user: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};

export default FavoriteUpdateCompanies;
