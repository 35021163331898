const dev = {
  // backend
  apiGateway: {
    REGION: "us-east-1",
    URL:
      process.env.REACT_APP_SERVER_URL ||
      "https://api-staging.belugahealth.com",
  },
};

const prod = {
  // backend
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.belugahealth.com",
  },
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default config;
