import React, { useState } from "react";
import PropTypes from "prop-types";

import FavoriteForm from "./FavoriteForm";
import ModalButton from "../../Components/ModalButton";

const FavoriteModalFormButton = (props) => {
  const {
    type,
    btnTitle,
    btnClassname,
    defaultFormData,
    onFormSubmit,
    onSuccess,
    visitTypes,
    pharmacies,
    mode,
    config,
    formCompareTo,
    company,
    customSubmitText,
  } = props;
  const modalTitle = props.modalTitle || btnTitle;

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (formData) => {
    const res = await onFormSubmit(formData, setErrorMessage);
    if (res) {
      onSuccess();
      setErrorMessage("");
      setShowModal(false);
    }
  };

  return (
    <ModalButton
      btnLabel={btnTitle}
      btnClassname={btnClassname}
      modalTitle={modalTitle}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <FavoriteForm
        type={type}
        mode={mode}
        defaultFormData={defaultFormData}
        compareTo={formCompareTo}
        visitTypes={visitTypes}
        pharmacies={pharmacies}
        onSubmit={handleSubmit}
        config={config}
        errorMessage={errorMessage}
        company={company}
        customSubmitText={customSubmitText}
      />
    </ModalButton>
  );
};

FavoriteModalFormButton.propTypes = {
  btnTitle: PropTypes.string.isRequired,
  btnClassname: PropTypes.string,
  defaultFormData: PropTypes.object,
  onFormSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  visitTypes: PropTypes.array,
  mode: PropTypes.string,
  config: PropTypes.array,
  formCompareTo: PropTypes.object,
  modalTitle: PropTypes.string,
};

export default FavoriteModalFormButton;
